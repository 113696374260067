import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Container, Content as StyledContent } from '../components/home/homeStyledComponents';
import { mobileThresholdPixels } from '../components/home/v3/styledComponents';
import MenuBar from '../components/home/MenuBar';
import LaRubriqueHeader from '../components/LaRubrique/LaRubriqueHeader';
import LaRubriqueListContent from '../components/LaRubrique/LaRubriqueListContent';
import LaRubriqueFooter from '../components/LaRubrique/LaRubriqueFooter';
import FootNote from '../components/LaRubrique/FootNote';
import FooterLightVersion from '../components/home/v3/FooterLightVersion';
import Layout from '../layouts/index';

const Content = styled(StyledContent)`
  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px 22px;
    box-sizing: border-box;
  }
`;

const ListTemplate = ({ pageContext: { posts, categories, category } }) => (
  <Layout
    routeSlug={(category?.slug) ? `LaRubriqueCategory_${category.slug}` : 'LaRubrique'}
  >
    <Container>
      <MenuBar />
      <Content>
        <LaRubriqueHeader category={category} categories={categories} />
        <LaRubriqueListContent posts={posts} category={category} />
      </Content>
      <LaRubriqueFooter category={category} preFilledSteps={category?.preFilledSteps} />
      {category && category.footNote
        && (
          <FootNote
            title={category.footNote.title}
            paragraph={category.footNote.paragraph}
            links={category.footNote.links}
          />
        )}
      <FooterLightVersion />
    </Container>
  </Layout>
);

ListTemplate.propTypes = {
  pageContext: PropTypes.shape({
    posts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    category: PropTypes.shape({
      slug: PropTypes.string,
      preFilledSteps: PropTypes.shape({}),
      footNote: PropTypes.shape({
        title: PropTypes.string,
        paragraph: PropTypes.string,
        links: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
    categories: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default ListTemplate;
