import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withAppContext from '../../withAppContext';
import Breadcrumb from '../LandingPage/Breadcrumb';
import Dots from '../home/v2/Dots';
import {
  mobileThresholdPixels, colors, fontSizes, margins, ContainerBlock, Column, Title2,
}
  from '../home/v2/styledComponents';
import { toFormattedText, toFormattedLinkedText } from '../../services/formatting';

const StyledContainerBlock = styled(ContainerBlock)`margin: 44px 0px;`;

const TextContainer = styled.p`
  font-family: Roboto;
  font-size: ${fontSizes.s};
  margin: auto;
  text-align: center;
  color: ${colors.navy};
  width: 900px;
  ${(props) => !props.withoutTitle && `
    margin-bottom: ${margins.m};
    text-align: justify;
  `}

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const FootNote = ({
  title, paragraph, links, itemListElement, context: { isMobile },
}) => (
  <StyledContainerBlock>
    <Column justifyContent="center" alignItems="center">
      <Title2>{!isMobile ? toFormattedText(title) : title.split('\n').join(' ')}</Title2>
      <Dots />
    </Column>
    <TextContainer withoutTitle={!title}>
      {toFormattedLinkedText(paragraph, links)}
    </TextContainer>
    {itemListElement && <Breadcrumb itemListElement={itemListElement} />}
  </StyledContainerBlock>
);

FootNote.propTypes = {
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.string.isRequired,
  ),
  itemListElement: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    item: PropTypes.string.isRequired,
  }).isRequired),
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

FootNote.defaultProps = {
  links: [],
  itemListElement: null,
};

export default withAppContext(FootNote);
